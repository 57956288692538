const stageList = [
  [
    // { id: 'learner_pupil_one', name: '一年级' },
    // { id: 'learner_pupil_two', name: '二年级' },
    { id: 'learner_pupil_three', name: '三年级' },
    { id: 'learner_pupil_four', name: '四年级' },
    { id: 'learner_pupil_five', name: '五年级' },
    { id: 'learner_pupil_six', name: '六年级' },
  ],
  [
    // { id: 'learner_junior_one_pupil_six', name: '六年级（五四制）' },
    { id: 'learner_junior_one', name: '七年级' },
    { id: 'learner_junior_two', name: '八年级' },
    { id: 'learner_junior_three', name: '九年级' },
  ],
]

const stageKeys = {
  learner_pupil_one: '一年级',
  learner_pupil_two: '二年级',
  learner_pupil_three: '三年级',
  learner_pupil_four: '四年级',
  learner_pupil_five: '五年级',
  learner_pupil_six: '六年级',
  learner_junior_one_pupil_six: '六年级（五四制）',
  learner_junior_one: '初一',
  learner_junior_two: '初二',
  learner_junior_three: '初三',
}

export default stageList
export { stageKeys }
